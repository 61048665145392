<template>
  <div class="TestKitList">

    <div class="form">
      <el-form ref="form" :inline="true" label-width="100px" :model="form" @submit.native.prevent >
        <el-row>
          <el-col :span="8">
            <el-form-item label="姓名" prop="username">
              <el-input v-model="form.username" clearable placeholder="请输入姓名"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="所属医院" prop="affiliatedHospital">
              <el-input v-model="form.affiliatedHospital" clearable placeholder="请输入所属医院"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="手机号" prop="phone">
              <el-input v-model="form.phone" clearable placeholder="请输入手机号"></el-input>
            </el-form-item>
          </el-col>
<!--          <el-col :span="8">
            <el-form-item label="身份证号" prop="idCardNo">
              <el-input v-model="form.idCardNo" clearable placeholder="请输入身份证号"></el-input>
            </el-form-item>
          </el-col>-->
<!--          <el-col :span="8">
            <el-form-item label="身份" prop="identify">
              <el-select v-model="form.identify" clearable placeholder="请选择身份">
                <el-option
                    v-for="item in identity_list"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>-->
          <el-col :span="24">
            <el-form-item class="TestKitList-form-button">
              <el-button @click="onSubmit(form)" type="primary" native-type="submit" >查询</el-button>
              <el-button @click="Reset" >重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <div class="list">
      <div class="TestKitList-list-handle">
        <el-button type="primary" @click="add" size="small" >添加</el-button>
      </div>
      <el-table
          :data="table"
          v-loading="tableLoad"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          style="width: 100%">
        <el-table-column
            prop="docName"
            align="center"
            label="医生姓名">
          <template slot-scope="scope">
            <span class="TestKitList-list-blue" @click="detail(scope.row)" >{{ scope.row.docName }}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="phoneNo"
            align="center"
            label="绑定手机">
        </el-table-column>
        <el-table-column
            prop="age"
            align="center"
            label="年龄">
        </el-table-column>
        <el-table-column
            prop="gender"
            align="center"
            label="性别">
          <template #default="scope" >
            <span>{{ gender_name(scope.row.gender) }}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="idCardNo"
            align="center"
            label="医生执业证号">
        </el-table-column>
        <el-table-column
            prop="affiliatedHospital"
            align="center"
            label="所属医院">
        </el-table-column>
<!--        <el-table-column
            prop=""
            min-width="360"
            align="center"
            label="功能列表">
          <template #default="scope" >
            <div class="fnListClass">
              <div class="list-box">
                <p>医生端:</p>
                <ul>
                  <template v-for="(item,index) in fn_list(scope.row.appletMenuInfo.appletMenuInfosD)" >
                    <li :key="index">{{ item }}</li>
                  </template>
                </ul>
              </div>
              <div class="list-box" >
                <p>患者端:</p>
                <ul>
                  <template v-for="(item,index) in fn_list(scope.row.appletMenuInfo.appletMenuInfosP)" >
                    <li :key="index">{{ item }}</li>
                  </template>
                </ul>
              </div>
            </div>
          </template>
        </el-table-column>-->
<!--        <el-table-column
            prop="identity"
            align="center"
            label="身份">
          <template #default="scope" >
            <span>{{ identity_name(scope.row.identity) }}</span>
          </template>
        </el-table-column>-->
<!--        <el-table-column
            prop="id"
            align="center"
            label="识别ID">
        </el-table-column>-->

<!--        <el-table-column
            fixed="right"
            align="center"
            label="操作">
          <template slot-scope="scope">
            <el-popconfirm
                title="确定删除该医生吗？"
                @confirm="del(scope.row)"
            >
              <el-button type="text" size="small" style="color: #f56c6c;" slot="reference">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>-->
      </el-table>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="form.pageNum"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="form.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>

  </div>
</template>

<script>
export default {
  name: "doctorManage",
  data() {
    return {
      form: {
        pageNum: 1,
        pageSize: 10,
        username: "",
        phone: "",
        idCardNo: "",
        identify: "",
      },
      table: [],
      tableLoad: false,
      total: 0,
      identity_list: [
        {
          value: '0',
          label: "全科医生"
        },
        {
          value: '1',
          label: "肠镜医生"
        },
      ],
      range: [
        {
          value: '1',
          text: "男",
        },
        {
          value: '0',
          text: "女",
        },
      ]
    }
  },
  computed: {
    identity_name() {
      return function (row) {
        let str;
        str = this.identity_list.filter(item => item.value === row).map(item => item.label)[0];
        return str;
      }
    },
    gender_name() {
      return function (row) {
        let str;
        str = this.range.filter(item => item.value === row).map(item => item.text)[0];
        return str;
      }
    },
    fn_list() {
      return function (arr) {
        return (arr || []).map(item => item.menuName);
      }
    }
  },
  created() {
    this.get_list();
  },
  mounted() {

  },
  methods: {
    onSubmit(y) {
      y.current= 1;
      this.get_list(y);
    },
    Reset() {
      this.$refs["form"].resetFields();
      this.$nextTick(() => {
        this.get_list();
      })
    },
    detail(row) {
      this.$router.push({
        path: "/doctorManageAdd",
        query: {
          id: row.id
        }
      })
    },
    add() {
      this.$router.push({
        path: "/doctorManageAdd"
      })
    },
    del(row) {
      console.log(row);
      this.$del(`docmanage/delete/${row.id}`)
        .then(res => {
          if(res) {
            console.log(res)
            this.get_list();
          }
        })
    },
    get_list() {
      this.$get("docmanage/list",this.form)
        .then(res => {
          if(res) {
            console.log(res);
            let data_ = res.data;
            this.table = data_.list;
            this.total = data_.total;
          }
        })
    },
    handleSizeChange(e) {
      this.form.pageSize= e;
      this.get_list();
    },
    handleCurrentChange(e) {
      this.form.pageNum= e;
      this.get_list();
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../../style/TestKit/TestKitList';
.fnListClass{
  width: 100%;
  display: flex;
  justify-content: space-between;
  .list-box{
    display: flex;
    >p{
      width: 50px;
    }
    >ul{
      li{
        min-width: 100px;
      }
    }
  }
  .list-box + .list-box{
    margin-left: 10px;
  }
}
</style>
